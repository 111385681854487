<template>
  <div id="worldMap" />
</template>
<script>
import $ from 'jquery';

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  async mounted() {
    // eslint-disable-next-line no-multi-assign
    window.$ = window.jQuery = $;
    // eslint-disable-next-line global-require
    require('jvectormap-next')($);
    await import('./world_map');
    this.initVectorMap();
  },
  methods: {
    initVectorMap() {
      window.$('#worldMap').vectorMap({
        map: 'world_mill_en',
        backgroundColor: 'transparent',
        zoomOnScroll: false,
        regionStyle: {
          initial: {
            fill: '#e4e4e4',
            'fill-opacity': 0.9,
            stroke: 'none',
            'stroke-width': 0,
            'stroke-opacity': 0,
          },
        },

        series: {
          regions: [
            {
              values: this.data,
              scale: ['#AAAAAA', '#444444'],
              normalizeFunction: 'polynomial',
            },
          ],
        },
      });
    },
  },
};
</script>
<style></style>
